import Vue from 'vue';
import exercisemodal from './webapp/ExerciseModal.vue';
import i18n from "./i18n";

export function addComparisonPageSelectorViewModel(staticUrl, exercise, comparisonExercise, strengthStandardsUrl) {
    new Vue({
        el: '#comparisonPageSelectorForm',
        i18n,
        data() {
            const initialExercises = window.prefill?.find(x => x.request?.url === '/api/exercises')?.response ?? null;
            return {
                initialExercises,
                exercise: exercise,
                comparisonExercise: comparisonExercise,
                staticUrl,
            };
        },
        components: {
            exercisemodal,
        },
        methods: {
            showExerciseModal(isComparison) {
                this.isComparison = isComparison;
                this.$refs.exerciseSelectorModal.showModal();
            },
            selectExercise(exerciseId, exercise) {
                if (this.isComparison) {
                    this.changePage(this.exercise, exercise);
                } else {
                    this.changePage(exercise, this.comparisonExercise);
                }
            },
            swap() {
                this.changePage(this.comparisonExercise, this.exercise);
            },
            changePage(exercise, comparisonExercise) {
                this.$refs.exerciseSelectorModal.hideModal();

                // Prevent comparing against oneself
                if (exercise.id !== comparisonExercise.id) {
                    window.location = this.getComparisonUrl(exercise, comparisonExercise);
                }
            },
            getComparisonUrl: function (exercise, comparisonExercise) {
                return strengthStandardsUrl + '/' + exercise.name_url + '-vs-' + comparisonExercise.name_url;
            },
            getStandardsUrl(exercise) {
                return strengthStandardsUrl + '/' + exercise.name_url;
            },
            getStandardsTitle(exerciseName) {
                return exerciseName + ' Strength Standards';
            },
        }
    });
}